<template>
  <div style="background-color: #FFF; min-height: 100vh;">
    <v-container fluid v-if="loading_open">
      <v-layout align-center justify-center row fill-height>
        <v-progress-circular
          :size="70"
          :width="7"
          color="success"
          indeterminate
        ></v-progress-circular>
      </v-layout>
    </v-container>
    <div id="app"  v-if="!loading_open">
      <!-- <ul id="menu" style="background-color:#000"></ul> -->
      <v-container fluid grid-list-md fill-height>
        <v-layout row wrap align-center justify-center fill-height>
          <!-- Web -->
          <v-flex xs12 sm12 md6 class="hidden-sm-and-down align-center justify-center" style="text-align: center; background: #efefef; padding: 3%; min-height: 100vh;">
            <!-- Right WEB -->
            <cpnHeaderWeb class="hidden-sm-and-down"/>
            <v-flex xs12 sm12 md12>
              <div class="header hidden-sm-and-down">
                <h1 class="title font-weight-black pb-4">Ecomize na sua conta de energia!</h1>
                <cpnFaq />
              </div>
            </v-flex>
          </v-flex>
          <!-- Mobile -->
          <v-flex xs12 sm12 md6 class="hidden-md-and-up" style="text-align: center; background: #FFF; padding: 3%;">
            <!-- Header Mobile -->
            <cpnHeaderMobile class="hidden-md-and-up" hasDuvidas="true"/>
            <v-flex xs12 sm12 md12>
              <div class="header hidden-md-and-up">
                <h1 class="title font-weight-black pb-4">Ecomize na sua conta de energia!</h1>
                <cpnSteps :steps="{steps: 5, e1: 1}"/>
              </div>
            </v-flex>
          </v-flex>
          <!-- <v-flex xs12 sm12 md12>
            <div class="header hidden-sm-and-down">
            <h1 class="title font-weight-black pb-4">Até 10% de economia na sua conta de energia</h1>
            <cpnFaq />
            </div>
            <div class="header hidden-md-and-up">
            <h1 class="headline font-weight-black">Até 10% de economia na sua conta de energia</h1>
            </div>
          </v-flex> -->
          <v-flex xs12 sm12 md6 style="text-align: center;" class="align-center">
            <div class="form_cadastro">
              <div style="padding-bottom: 15px;">
                <cpnSteps class="hidden-sm-and-down" :steps="{steps: 5, e1: 1}"/>
                <div
                  class="display-1 hidden-sm-and-down"
                  style="padding-bottom: 25px; color: #808080; padding-top: 40px"
                >QUERO ECONOMIZAR</div>
                <!-- <div
                  class="headline hidden-sm-and-up"
                  style="padding-bottom: 25px; color: #808080"
                >QUERO ECONOMIZAR</div> -->
              </div>
              <v-form ref="form" v-model="valid" lazy-validation>
                <v-text-field
                  v-model="email"
                  :rules="emailRules"
                  label="*E-mail"
                  prepend-icon="email"
                  name="email"
                  type="email"
                  required
                ></v-text-field>
                <v-text-field
                  v-model="name"
                  name="name"
                  :rules="nameRules"
                  label="*Nome Completo"
                  prepend-icon="person"
                  required
                ></v-text-field>
                <v-text-field
                  type="tel"
                  v-model="phone"
                  :rules="phoneRules"
                  prepend-icon="phone"
                  label="*Celular"
                  return-masked-value
                  mask="(##)#####-####"
                  required
                ></v-text-field>
                <!-- <v-text-field
                  v-model="zipcode"
                  name="zipcode"
                  :rules="[
                    v => !!v || 'Por favor, informe seu CEP!'
                  ]"
                  label="*CEP"
                  mask="#####-###"
                  prepend-icon="place"
                  required
                ></v-text-field> -->
                <header
                  style="text-align: left;padding-bottom: 4px"
                >Quanto você gasta de luz por mês?</header>

                <v-text-field
                  type="tel"
                  v-model="average_consumption"
                  prefix="R$"
                  value="0"
                  :rules="[
                    v => !!v || 'Por favor, informe sua média de gastos!',
                    v => v > 199 || 'Você deve ter uma média de gastos superior a R$200 para assinar o serviço'
                  ]"
                  prepend-icon="money"
                  return-masked-value
                  mask="########"
                  suffix=",00"
                  required
                ></v-text-field>
                <header style="text-align: left;">Qual a sua concessionária?</header>

                <v-radio-group
                  v-model="concessionaria"
                  style="padding: 3px 0 0 3px;"
                  :rules="[
                    v => !!v || 'Por favor, selecione a concessionária!'
                  ]"
                  row
                >
                  <v-radio :key="1" :label="`Cemig`" value="cemig" style="padding-right: 10px"></v-radio>
                  <v-radio :key="2" :label="`Outro`" value="outro"></v-radio>
                </v-radio-group>
                <v-flex xs12 sm12 md12 style="text-align: right">
                  <v-btn
                    :disabled="!valid"
                    color="success"
                    @click.prevent="validate"
                    block
                    large
                    :loading="loading"
                    type="submit"
                  >CONTINUAR</v-btn>
                </v-flex>
              </v-form>
              <!-- <v-progress-circular v-if="loading" :size="50" color="teal" indeterminate></v-progress-circular> -->
            </div>
          </v-flex>
          <v-flex class="hidden-md-and-up" style="background:#efefef">
            <div style="padding: 5% 5% 5% 5%; font-weight: 800">Dúvidas?</div>
            <div class="title" id= "faq">
                <cpnFaq />
            </div>
          </v-flex>
        </v-layout>
      </v-container>
    </div>
    <!-- Modal de Faq -->
    <div class="text-xs-center">
      <v-dialog v-model="openModal" width="500">
        <v-card style="background:rgb(0, 168, 187)">
          <div class="text-xs-left pt-2 pb-2 pl-2 pr-2">
            <v-icon
              style="font-size: 1.5em; color: white; float: right"
              @click="openModal=false"
            >close</v-icon>
          </div>
          <div class="pb-2">
            <span class="pl-2 white--text font-weight-bold display-inline">Como Funciona</span>
            <iframe
              allowfullscreen
              style="border: none; width: 100%"
              title="Player de Vídeo youtube"
              src="https://www.youtube.com/embed/Qa1QUEaklys?feature=oembed&amp;start&amp;end&amp;wmode=opaque&amp;loop=0&amp;controls=1&amp;mute=0&amp;rel=0&amp;modestbranding=1"
            ></iframe>
          </div>
          <span class="pl-2 white--text font-weight-bold display-inline">Dúvidas Frequentes</span>
          <cpnFaq class="pl-2 pr-2" />
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>
<script>
// import { addLead } from "@/services/leads";
import { STORE_LEAD, GET_LEAD } from "../../graphql/leads"
// import storeLead from "@/services/lead_graphql";
import { enviarEmail } from "@/services/sendmail"
import { saveAnalytics } from "@/services/save_analytics"
import { SET_CONVERSION } from "../../graphql/rdstation"
import Faq from "../../components/faq/Faq"
import HeaderWeb from "../../components/web/Header"
import HeaderMobile from "../../components/mobile/Header"
import Steps from '../../components/core/Steps'
// import { setRDConversion } from "@/services/rdstation";
// import amplitude from 'amplitude-js';

export default {
  components: {
    cpnFaq: Faq,
    cpnHeaderWeb: HeaderWeb,
    cpnHeaderMobile: HeaderMobile,
    cpnSteps: Steps
  },
  data() {
    return {
      openModal: false,
      valid: false,
      concessionarias: ["Cemig", "Outro"],
      email: "",
      emailRules: [v => !!v || "Por favor, informe o seu email", v => /.+@.+/.test(v) || "E-mail precisa ser válido"],
      name: "",
      nameRules: [
        v => !!v || "Por favor, informe o seu nome",
        v => v.length >= 10 || "Por favor, preencha seu nome completo."
      ],
      zipcode: "",
      concessionaria: "",
      phone: "",
      phoneRules: [
        v => !!v || "Por favor, informe o seu celular",
        v => v.length > 10 || "Ops. Parece que esse não é um número de telefone"
      ],
      promo_code: this.$route.query.referral_code || "",
      average_consumption: "",
      step: 0,
      customer_id: "",
      show1: false,
      rules: {
        required: value => !!value || "Obrigatório.",
        min: v => v.length >= 8 || "Min 8 caracteres",
        emailMatch: () => "E-mail ou senha incorretos"
      },
      interval: {},
      progress: 0,
      loading: false,
      loading_open: true,
      isProd: false,
      utm_source: "",
      utm_medium: "",
      utm_campaign: "",
      lead: {},
      cpf: "",
      titular: ""
    }
  },
  beforeDestroy() {
    this.loading = false
  },
  created() {
    this.loading_open = true
    this.isProd = process.env.NODE_ENV === "production"
    this.getUTM()
    // console.log('email: ', this.$route.query.email)
    if (this.isProd) {
      const analytics = {
        eventCategory: "onboard",
        eventAction: "VIEWED_ONBOARD",
        eventLabel: "VIEWED_ONBOARD",
        value: "",
        utm_source: this.utm_source,
        utm_medium: this.utm_medium,
        utm_campaign: this.utm_campaign,
      }
      saveAnalytics(analytics)
    }
    if(this.$route.query.email) {
      const email = this.$route.query.email
      this.getLead(email)
        .then(lead => {
          this.setLead(lead.data.lead)
          this.loading_open = false
        })
        .catch(() => {
          console.error('Email não encontrado: ', email)
          this.loading_open = false
        })
    } else {
      this.lead = JSON.parse(localStorage.getItem("lead"))
      if(this.lead && this.lead.email) {
        this.setLead(this.lead)
      }
      this.loading_open = false
    }
  },
  methods: {
    validate() {
      if (this.$refs.form.validate()) {
        this.signUp()
      }
    },
    async getLead(email) {
      return await this.$apollo.query({
        query: GET_LEAD,
        variables: {
          email: email
        }
      })
    },
    async setLead(lead) {
      this.email = lead.email
      this.name = lead.name
      this.zipcode = lead.zipcode || ""
      this.utm_source = lead.utm_source || ""
      this.utm_medium = lead.utm_medium || ""
      this.utm_campaign = lead.utm_campaign || ""
      this.concessionaria = lead.concessionaria || ""
      this.phone = lead.phone || ""
      this.promo_code = lead.promo_code || ""
      this.average_consumption = lead.average_consumption || ""
      this.step = lead.step || ""
      this.cpf = lead.cpf || ""
      this.titular = lead.titular || ""
    },
    reset() {
      this.$refs.form.reset()
    },
    resetValidation() {
      this.$refs.form.resetValidation()
    },
    signUp: function() {
      this.loading = true
      this.email = this.email.replace(" ", "")
      if (this.email.trim() === "") {
        console.error("Erro: Email é obrigatório")
        return 0
      }
      let user = {
        customer_id: this.email,
        email: this.email,
        name: this.name,
        zipcode: this.zipcode,
        concessionaria: this.concessionaria,
        phone: this.phone,
        promo_code: this.promo_code,
        average_consumption: this.average_consumption,
        step: 3,
        utm_source: this.utm_source,
        utm_medium: this.utm_medium,
        utm_campaign: this.utm_campaign,
        cpf: this.cpf,
        titular: this.titular
      }
      // vuex storelead
      this.$store.commit("storeUserState", user)

      this.storeLead(user, 3)
        .then(result => {
          if (result.data) {
            localStorage.setItem("lead", JSON.stringify(user))
            if (this.isProd) {
              // analytics
              const analytics = {
                eventCategory: "onboard",
                eventAction: "CREATE-NEW-LEAD",
                eventLabel: "CREATE-NEW-LEAD",
                value: this.average_consumption,
                utm_source: user.utm_source,
                utm_medium: user.utm_medium,
                utm_campaign: user.utm_campaign,
              }
              saveAnalytics(analytics)

              // rd station
              const conversion = {
                event_type: "CONVERSION",
                event_family: "CDP",
                payload: {
                  conversion_identifier: "onboarding",
                  name: user.name,
                  email: user.email,
                  mobile_phone: user.phone ? user.phone : "",
                  traffic_source: user.utm_source,
                  traffic_medium: user.utm_medium,
                  traffic_campaign: user.utm_campaign,
                  tags: ["lead", "onboarding", user.average_consumption * 1 > 200 ? "maior 200" : "ate 200"],
                  available_for_mailing: true,
                  cf_consumo_medio_mensal: user.average_consumption,
                  cf_grupo_consumo_medio_mensal: user.average_consumption * 1 > 200 ? "maior 200" : "ate 200",
                  cf_concessionaria: user.concessionaria
                }
              }
              this.setRDConversion(conversion)

              // if(user.average_consumption * 1 > 300) {
              //   const conversion = {
              //     event_type: "OPPORTUNITY",
              //     event_family: "CDP",
              //     payload: {
              //       email: user.email,
              //       funnel_name: "default"
              //     }
              //   }
              //   this.setRDConversion(conversion)
              // }
            }

            if (user.concessionaria === "cemig") {
              // this.loading = false;
              this.$router.push("/cadastro/unidadeconsumidora")
            } else {
              this.loading = false
              const data = {
                email: user.email,
                name: user.name,
                dynamic_template_data: {
                  name: user.name
                },
                template_id: process.env.VUE_APP_SENDGRID_TEMPLATE_LISTAESPERA
              }
              enviarEmail(data)
                .then(this.$router.replace("lista-espera"))
                .catch(error => {
                  console.error("Error: Erro ao enviar email", error)
                  this.$router.replace("lista-espera")
                })
            }
          }
        })
        .catch(err => {
          this.loading = false
          console.error("Error: ", err)
        })
    },
    storeLead(lead, step) {
      return this.$apollo.mutate({
        // Query
        mutation: STORE_LEAD,
        // Parameters
        variables: {
          id: lead.email,
          concessionaria: lead.concessionaria,
          email: lead.email,
          name: lead.name,
          zipcode: lead.zipcode,
          phone: lead.phone,
          average_consumption: lead.average_consumption,
          step: step,
          promo_code: lead.promo_code,
          utm_source: lead.utm_source,
          utm_medium: lead.utm_medium,
          utm_campaign: lead.utm_campaign
        }
      })
    },
    getCookie(cname) {
      var name = cname + "="
      var ca = document.cookie.split(";")
      for (var i = 0; i < ca.length; i++) {
        var c = ca[i]
        while (c.charAt(0) == " ") {
          c = c.substring(1)
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length)
        }
      }
      return ""
    },
    getUTM() {
      if (this.$route.query.utm_source) {
        this.utm_source = this.$route.query.utm_source
        localStorage.setItem("utm_source", this.utm_source)
        // } else if (window.$cookies.isKey('utm_source')) {
      } else if (this.getCookie("utm_source")) {
        this.utm_source = this.getCookie("utm_source")
        localStorage.setItem("utm_source", this.utm_source)
      } else if (localStorage.getItem("utm_source")) {
        this.utm_source = localStorage.getItem("utm_source")
      } else {
        this.utm_source = "direct"
      }

      if (this.$route.query.utm_medium) {
        this.utm_medium = this.$route.query.utm_medium
        localStorage.setItem("utm_medium", this.utm_medium)
        // } else if (window.$cookies.isKey('utm_medium')) {
      } else if (this.getCookie("utm_medium")) {
        this.utm_medium = this.getCookie("utm_medium")
        localStorage.setItem("utm_medium", this.utm_medium)
      } else if (localStorage.getItem("utm_medium")) {
        this.utm_medium = localStorage.getItem("utm_medium")
      } else {
        this.utm_medium = "direct"
      }

      if (this.$route.query.utm_campaign) {
        this.utm_campaign = this.$route.query.utm_campaign
        localStorage.setItem("utm_campaign", this.utm_campaign)
        // } else if (window.$cookies.isKey('utm_campaign')) {
      } else if (this.getCookie("utm_campaign")) {
        this.utm_campaign = this.getCookie("utm_campaign")
        localStorage.setItem("utm_campaign", this.utm_campaign)
      } else if (localStorage.getItem("utm_campaign")) {
        this.utm_campaign = localStorage.getItem("utm_campaign")
      } else {
        this.utm_campaign = "direct"
      }
    },
    setRDConversion(conversion) {
      return this.$apollo.mutate({
        // Query
        mutation: SET_CONVERSION,
        // Parameters
        variables: {
          event_type: conversion.event_type,
          event_family: conversion.event_family,
          payload: conversion.payload
        }
      })
    }
  }
}
</script>

<style scoped>
@charset "ISO-8859-1";


/* Reset CSS
 * --------------------------------------- */
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
fieldset,
input,
textarea,
p,
blockquote,
th,
td {
  padding: 0;
  margin: 0;
}
a {
  text-decoration: none;
}

#app {
  /* font-family: "Rubik", sans-serif; */
  font-family: "Poppins", cursive;
}

fieldset,
img {
  border: 0;
}
ol,
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
caption,
th {
  text-align: left;
}
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
  font-size: 100%;
  margin: 0;
  padding: 0;
}
q:before,
q:after {
  content: "";
}
abbr,
acronym {
  border: 0;
}
* {
  -webkit-box-sizing: border-box; /* Safari<=5 Android<=3 */
  -moz-box-sizing: border-box; /* <=28 */
  box-sizing: border-box;
}

/* Custom
 * --------------------------------------- */
html,
body {
  font-family: "Poppins", cursive;
  height: 100%;
}
.header {
  padding-top: 40px;
  padding-bottom: 0px;
  font-size: 1.5em;
  vertical-align: -webkit-baseline-middle;
  vertical-align: middle;
  display: table-cell;
  color: rgb(51, 51, 51);
}
.header span {
  font-size: 1.5em;
  color: #666;
  font-weight: bold;
}
.header h1 {
  font-size: 1.8em;
  color: #666;
  font-weight: bold;
}
.header br {
  font-size: 3em;
  color: #666;
  font-weight: bold;
}
.form_cadastro {
  height: 100%;
  vertical-align: -webkit-baseline-middle;
  vertical-align: middle;
  background-color: #fff;
  padding: 2% 5% 2% 5%;
  text-align: center;
  border-radius: 10px;
}
.direita {
  width: 95%;
  padding-left: 5%;
  padding-top: 10%;
  text-align: left;
}

h3 {
  font-size: 4em;
  /* text-align: center; */
  color: #666;
  font-weight: bold;
}
h2 {
  font-size: 2em;
  /* text-align: center; */
  color: #666;
  font-weight: bold;
}
#logo {
  position: fixed;
  top: 20px;
  left: 20px;
  color: #fff;
  font-weight: bold;
  z-index: 99;
  font-size: 1.9em;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
}

/* Menu
 * --------------------------------------- */
#menu-line {
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 159px;
  height: 2px;
  background: #fff;
}

#menu {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 70;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  letter-spacing: 1px;
  font-size: 1.1em;
}
#menu li {
  display: inline-block;
  margin: 10px 0px;
  position: relative;
}
#menu a {
  color: #fff;
  padding: 0 1.1em 1.1em 1.1em;
}
#menu li.active a:after {
  content: "";
  margin: 0 1.1em 0 1.1em;
  height: 2px;
  background: #fff;
  display: block;
  position: absolute;
  bottom: -6px;
  left: 0;
  right: 0;
  display: block;
}
.v-progress-circular {
  margin: 1rem;
}
</style>
